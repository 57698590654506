/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: [],
};

export const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setQuestions: (state, action) => {
      state.value = action.payload;
    },
    addQuestion: (state, action) => {
      state.value.push(action.payload);
    },
  },
});

export const { setQuestions, addQuestion } = questionsSlice.actions;

export default questionsSlice.reducer;
