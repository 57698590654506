import { configureStore } from '@reduxjs/toolkit';

import userReducer from './userSlice';
import subjectReducer from './subjectSlice';
import feedbackModalVisibleReducer from './feedbackModalVisibleSlice';
import stageReducer from './examStageSlice';
import workReducer from './examWorkSlice';
import questionsReducer from './examQuestionsSlice';
import stopwatchReducer from './examStopwatchSlice';
import userQuestionResponsesSliceReducer from './examUserQuestionResponsesSlice';
import userWorkResponseSliceReducer from './examUserWorkResponseSlice';

// eslint-disable-next-line import/prefer-default-export
export const store = configureStore({
  reducer: {
    user: userReducer,
    subject: subjectReducer,
    feedbackModalVisible: feedbackModalVisibleReducer,
    examStage: stageReducer,
    examWork: workReducer,
    examQuestions: questionsReducer,
    examStopwatch: stopwatchReducer,
    examUserQuestionResponses: userQuestionResponsesSliceReducer,
    examUserWorkResponse: userWorkResponseSliceReducer,
  },
});
