import './index.css';

import React, { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';

import { Auth0Provider } from '@auth0/auth0-react';

import { ConfigProvider } from 'antd';
import zhTW from 'antd/lib/locale/zh_TW';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import TagManager from 'react-gtm-module';

import { store } from './store';

import * as serviceWorker from './serviceWorker';

const App = lazy(() => import('./App'));
const Exam = lazy(() => import('./Exam'));
const Prompt = lazy(() => import('./Prompt'));
const Operation = lazy(() => import('./Operation'));

const queryClient = new QueryClient();

TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ConfigProvider locale={zhTW}>
          <QueryClientProvider client={queryClient}>
            <Auth0Provider
              domain={process.env.REACT_APP_AUTH0_DOMAIN}
              clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
              authorizationParams={{
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                redirect_uri: window.location.origin,
              }}
            >
              <QueryClientProvider client={queryClient}>
                <Suspense fallback={<div />}>
                  <Routes>
                    <Route path="*" element={<App />} />
                    <Route path="/exam/*" element={<Exam />} />
                    <Route path="/prompt/*" element={<Prompt />} />
                    <Route path="/op/*" element={<Operation />} />
                  </Routes>
                </Suspense>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
              {/* <App /> */}
            </Auth0Provider>
          </QueryClientProvider>
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);

serviceWorker.unregister();
