/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: [],
};

export const stopwatchSlice = createSlice({
  name: 'stopwatch',
  initialState,
  reducers: {
    setStopwatch: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setStopwatch } = stopwatchSlice.actions;

export default stopwatchSlice.reducer;
