/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: [],
};

export const userQuestionResponsesSlice = createSlice({
  name: 'userQuestionResponses',
  initialState,
  reducers: {
    resetUserQuestionResponses: (state) => {
      state.value = [];
    },
    updateAnswer: (state, action) => {
      const { questionId, answer, stopwatchMs } = action.payload;
      if (questionId === undefined || answer === undefined) {
        return;
      }

      const userQuestionResponseIdx = state.value.findIndex((uqr) => uqr.questionId === questionId);

      if (userQuestionResponseIdx !== -1) {
        state.value[userQuestionResponseIdx].answer = answer;
      } else {
        state.value.push({
          questionId,
          answer,
          durationLog: [],
          selfConfidence: 0,
        });
      }
    },
    addAnswerLog: (state, action) => {
      const { questionId, answerLog } = action.payload;
      if (questionId === undefined || answerLog === undefined) {
        return;
      }
      const userQuestionResponseIdx = state.value.findIndex((uqr) => uqr.questionId === questionId);
      if (!userQuestionResponseIdx !== -1) {
        state.value[userQuestionResponseIdx].answerLog.push(answerLog);
      } else {
        state.value.push({
          questionId,
          answer: [],
          answerLog: [answerLog],
          durationLog: [],
          selfConfidence: 0,
        });
      }
    },
    addDurationLog: (state, action) => {
      const { questionId, durationLog } = action.payload;
      if (questionId === undefined || durationLog === undefined) {
        return;
      }
      const userQuestionResponseIdx = state.value.findIndex((uqr) => uqr.questionId === questionId);
      if (userQuestionResponseIdx !== -1) {
        state.value[userQuestionResponseIdx].durationLog.push(durationLog);
      } else {
        state.value.push({
          questionId,
          answer: [],
          answerLog: [],
          durationLog: [durationLog],
          selfConfidence: 0,
        });
      }
    },
  },
});

export const {
  updateAnswer, addAnswerLog, addDurationLog, resetUserQuestionResponses,
} = userQuestionResponsesSlice.actions;

export default userQuestionResponsesSlice.reducer;
