/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 'ready', // ready, working, timeup, submit
};

export const stageSlice = createSlice({
  name: 'stage',
  initialState,
  reducers: {
    ready: (state) => {
      state.value = 'ready';
    },
    start: (state) => {
      state.value = 'working';
    },
    timeup: (state) => {
      state.value = 'timeup';
    },
    submit: (state) => {
      state.value = 'submit';
    },
  },
});

export const {
  ready, start, timeup, submit,
} = stageSlice.actions;

export default stageSlice.reducer;
