/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: {
    user_work_response_id: 0,
  },
};

export const userWorkResponseSlice = createSlice({
  name: 'userWorkResponse',
  initialState,
  reducers: {
    setUserWorkResponseId: (state, action) => {
      state.value.user_work_response_id = action.payload;
    },
  },
});

export const { setUserWorkResponseId } = userWorkResponseSlice.actions;

export default userWorkResponseSlice.reducer;
