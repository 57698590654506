/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const feedbackModalVisibleSlice = createSlice({
  name: 'subject',
  initialState,
  reducers: {
    setFeedbackModalVisible: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setFeedbackModalVisible } = feedbackModalVisibleSlice.actions;

export default feedbackModalVisibleSlice.reducer;
