/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: [],
};

export const workSlice = createSlice({
  name: 'work',
  initialState,
  reducers: {
    configSet: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { configSet } = workSlice.actions;

export default workSlice.reducer;
